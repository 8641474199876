/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components), {ContentImage} = _components;
  if (!ContentImage) _missingMdxReference("ContentImage", true);
  return React.createElement(React.Fragment, null, React.createElement("hr"), "\n", React.createElement("h5", {
    className: "mb-2"
  }, "System Design"), "\n", React.createElement(ContentImage, {
    imagePath: "z-dance/zdance-diagram.png",
    alt: "Z-Dance Exhibit Diagram",
    wrapperClasses: "mb-2"
  }), "\n", React.createElement(_components.p, null, "The exhibit structure was designed and built by Zeum staff in collaboration with media and software designer John Crawford."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
